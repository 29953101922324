import React, { PropsWithChildren } from 'react'

export type TriangleProps =  React.SVGProps<SVGSVGElement> & {
  color?: string
}

export const TriangleSvg: React.FC<PropsWithChildren<TriangleProps>> = ({ color, ...props }) => {  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 127 127">      
      <g fill="none" >
        <path stroke="none" d="M62.5,0,125,109H0Z"/>
        <path fill={color} d="M 62.5 6.031082153320312 L 5.178375244140625 106 L 119.8216247558594 106 L 62.5 6.031082153320312 M 62.5 0 L 125 109 L 0 109 L 62.5 0 Z"/>
      </g>
    </svg>
  )
}

export const RedTriangleSvg: React.FC<PropsWithChildren<TriangleProps>> = ({ ...props }) => {  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 124.1 115.8" >
      <path id="Polygon_RedTriangleSvg" fill="#F35A21" d="M49.5,6L4.8,112.2l113.8-14L49.5,6 M48.8,0l75.3,100.6L0,115.8L48.8,0z"/>
    </svg>
  )
}

export const OrangeTriangleSvg: React.FC<PropsWithChildren<TriangleProps>> = ({ ...props }) => {  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}  viewBox="0 0 69.8 69.7" >
      <path id="Polygon_OrangeTriangleSvg" fill="#ED8B00" d="M5.8,49.5l59.7,16l-16-59.6L5.8,49.5 M0,51L51.1,0l18.7,69.7L0,51z"/>  
    </svg>
  )
}

export const YellowTriangleSvg: React.FC<PropsWithChildren<TriangleProps>> = ({ ...props }) => {  
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 38.5 42" >
      <path id="Polygon_YellowTriangleSvg" fill="#FFB81C" d="M31.7,36.5L35,4.9L6,17.8L31.7,36.5 M34.1,42L0,17.1L38.5,0L34.1,42z"/>
    </svg>
  )
}